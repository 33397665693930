<template >
  <div class="home">

    <section class="z10">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators one">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="../assets/slide_2.jpg" alt="Second slide">
            <div class="carousel-caption right">
              <h5><span>WELCOME BONUS</span><br> 200% up to $1000!</h5>
              <p>Short description of this promo</p>
              <a href="" class="button text-only">Learn more <i class="fal fa-chevron-right"></i></a>
            </div>
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="../assets/slide_1.jpg" alt="First slide">
          </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>
    <section class="black promotion-list">
      <div class="Section__layoutContainer">
        <div class="Section__layout">
          <div class="Section__title">
            <h5>JOIN A WEBSITE THAT VALUES YOUR ENTERTAINMENT ABOVE EVERYTHING</h5>
            <h2><span>Latest</span> Promotions</h2>
          </div>

          <splide id="promo" data-splide='{"type":"loop","perPage":3,"height":100,"autoHeight":"true"}'>
            <splide-slide>
              <a href="/#/promotions/single-promo">
                <div class="overlay">
                  <h2>Promotion title overlay</h2>
                </div>
                <img src="../assets/promotion_thumbnail.jpg" class="img-thumbnail" />
              </a>
            </splide-slide>
            <splide-slide>
              <a href="/#/promotions/single-promo">
                <div class="overlay">
                  <h2>Promotion title overlay</h2>
                </div>
                <img src="../assets/promotion_thumbnail_2.jpg" class="img-thumbnail" />
              </a>
            </splide-slide>
            <splide-slide>
              <a href="/#/promotions/single-promo">
                <div class="overlay">
                  <h2>Promotion title overlay</h2>
                </div>
                <img src="../assets/promotion_thumbnail_3.jpg" class="img-thumbnail" />
              </a>
            </splide-slide>
            <splide-slide>
              <<a href="/#/promotions/single-promo">
              <div class="overlay">
                <h2>Promotion title overlay</h2>
              </div>
              <img src="../assets/promotion_thumbnail_2.jpg" class="img-thumbnail" />
            </a>
            </splide-slide>
          </splide>
          <div class="mt-5">
            <button class="button primary mt-4 mediumPadding">All promotions</button>
          </div>

        </div>
      </div>

    </section>
    <section class="black whybetkings">
      <div class="Section__layoutContainer">
        <div class="Section__layout text-right">
          <div class=" our-stats">

            <div class=" ">
              <div class="Section__title text-center">
                <h2><span>Why</span> Betkings</h2>
                <p class="make-readable-bg white">Not just another gaming website: with BetKings you are joining the idea that game is not always about numbers,

                  right calls and strategy. Above everything there is one thing gaming should be: FUN. </p>
              </div>
            </div>
          </div>
          <div class="ColumnLayout four our-stats">
            <div class="wrap">
              <h4>Jackpot</h4>
              <div class="number">109,768</div>
            </div>
            <div class="wrap">
              <h4>AOF Jackpot</h4>
              <div class="number">209,768</div>
            </div>
            <div class="wrap">
              <h4>Short Deck Flush Jackpot</h4>
              <div class="number">209,768</div>
            </div>
            <div class="wrap">
              <h4>Big hand Jackpot</h4>
              <div class="number">209,768</div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="black" id="download">
      <div class="Section__layoutContainer">
        <div class="Section__layout">

          <div class="ColumnLayout two">
            <div>
              <img src="../assets/devices.png" style="max-width: 700px; width: 100%;" />
            </div>
            <div>
              <div class="Section__title text-left">
                <h2><span>Play</span> Anywhere</h2>
                <p>With refined desktop and mobile experiences, you can always find a table whenever you like. Have just a couple of minutes? All in or Fold on your phone it is then!</p>
                <h4 class="in-content-title">Desktop</h4>
                <a href="" class="button download mr-2">
                  <i class="fab fa-windows"></i>
                  <div>
                    <span>Download for</span><br>
                    <label>Windows</label>
                  </div>
                </a>
                <a href="" class="button download mr-2">
                  <i class="fab fa-apple"></i>
                  <div>
                    <span>Download for</span><br>
                    <label>MacOS</label>
                  </div>
                </a>
                <a href="" class="button download">
                  <i class="fab fa-linux"></i>
                  <div>
                    <span>Download for</span><br>
                    <label>Linux</label>
                  </div>
                </a>
                <h4 class="in-content-title">Mobile</h4>
                <a href="" class="button download mr-2">
                  <i class="fab fa-google-play"></i>
                  <div>
                    <span>Get it on</span><br>
                    <label>Google Play</label>
                  </div>
                </a>
                <a href="" class="button download mr-2">
                  <i class="fab fa-android"></i>
                  <div>
                    <span>Download for</span><br>
                    <label>Android APK</label>
                  </div>
                </a>
                <a href="" class="button download mr-2">
                  <i class="fab fa-apple"></i>
                  <div>
                    <span>Download on the</span><br>
                    <label>Apple store</label>
                  </div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="black-grey whybetkings">
      <div class="Section__layoutContainer">
        <div class="Section__layout">
          <div class="Section__title">
            <h2>Our App Features</h2>
            <p>Find out why our software is just so good for your poker fun!</p>
          </div>
          <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators two">
              <li data-target="#carouselExampleIndicators2" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="ColumnLayout six features">
                  <div>
                    <div class="icon"><i class="fal fa-gem"></i></div>
                    <h4>High stakes feature table</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-globe"></i></div>
                    <h4>Lowest tournaments fees</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-chart-pie-alt"></i></div>
                    <h4>Staking platform</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-spinner"></i></div>
                    <h4>Fish Buffet spins</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-shield-alt"></i></div>
                    <h4>All-in insurance</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-forward"></i></div>
                    <h4>Straddle</h4>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="ColumnLayout six features">


                  <div>
                    <div class="icon"><i class="fal fa-dice-three"></i></div>
                    <h4>Run it three times</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-magic"></i></div>
                    <h4>Smart HUD</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-hand-sparkles"></i></div>
                    <h4>Smart betting</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-comment-dollar"></i></div>
                    <h4>Bubble protection</h4>
                  </div>
                  <div>
                    <div class="icon"><i class="fal fa-chart-line"></i></div>
                    <h4>PokerCraft</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
