import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
global.$ = jQuery
import 'bootstrap/dist/js/bootstrap.js';
import 'granim/dist/granim.js';
import 'bootstrap/dist/css/bootstrap.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import VueSplide from '@splidejs/vue-splide';

Vue.use( VueSplide );
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


$(document).ready(function fixedHeader() {

  var windows = $(window);
  var screenSize = windows.width();
  var sticky = $('.navbar');
  var $html = $('html');
  var $body = $('body');

  windows.on('scroll', function () {
    var scroll = windows.scrollTop();
    var headerHeight = sticky.height();

    if (screenSize >= 320) {
      if (scroll < headerHeight) {
        sticky.removeClass('is-sticky');
      } else {
        sticky.addClass('is-sticky');
      }
    }
  });
});

export default {
  data() {
    return {
      options: {
        rewind : true,
        width  : 800,
        gap    : '1rem',
        perPage: 3,
      },
    };
  },
}